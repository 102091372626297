<template>
  <el-config-provider :locale="locale">
    <Layout>
      <router-view />
    </Layout>
  </el-config-provider>
</template>

<script lang="ts" setup>
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import Layout from '@/views/Layout.vue'

const locale = zhCn
</script>

<style lang="scss">
#app {
  .loading {
    .el-icon-loading {
      top: 50%;
      left: 50%;
      margin-left: -55px;
      background: rgba(0, 0, 0, 0.7);
      padding: 20px;
      border-radius: 4px;
      width: auto;
      text-align: center;
      position: absolute;

      i {
        color: #eee;
      }

      .el-loading-text {
        color: #eee;
      }
    }
  }
}
</style>
