import { RouteRecordRaw } from 'vue-router'
// import Layout from '@/views/layouts/index.vue'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '首页',
      keepAlive: false
    },
    component: () => import('@/views/index.vue')
  }
]
