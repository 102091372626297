<template>
  <div class="header">
    <div :style="{ width: isShowMenu ? '80%' : '90%' }" class="content">
      <!--logo-->
      <div :style="{ width: isShowTitle ? '360px' : '170px' }" class="logo">
        <div class="img">
          <img :src="Logo" alt="衍景|精准留客，精准拓客" title="衍景" />
        </div>
        <div class="name">衍景</div>
        <div v-if="isShowTitle" class="v-bar">|</div>
        <div v-if="isShowTitle" class="desc">精准留客，精准拓客</div>
      </div>
      <!--菜单-->
      <div v-if="isShowMenu" class="menu">
        <div class="body">
          <el-menu
            :default-active="data.activeIndex"
            :ellipsis="false"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect">
            <template v-for="item in list" :key="item.id">
              <el-menu-item v-if="!item.children" :index="item.id.toString()"
              >{{ item.title }}
              </el-menu-item>
              <el-sub-menu v-else :index="item.id.toString()">
                <template #title>{{ item.title }}</template>
                <el-menu-item
                  v-for="val in item.children"
                  :key="val.id"
                  :index="item.id + '-' + val.id">
                  {{ val.title }}
                </el-menu-item>
              </el-sub-menu>
            </template>
          </el-menu>
        </div>
      </div>
      <!--其他-->
      <div class="other">
        <div v-if="isShowMenu" class="login" @click.stop="loginHandle">登录</div>
        <div v-if="isShowMenu" class="v-bar">|</div>
        <div v-if="isShowMenu" class="tryOut">
          <el-button>申请试用</el-button>
        </div>
        <div v-if="!isShowMenu" class="menu-btn" @click.stop="clickHandle">
          <el-icon>
            <Menu />
          </el-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Logo from "@/assets/logo.png";
import { Menu } from "@element-plus/icons-vue";
import { MenuType } from "@/views/typs";
import { reactive } from "vue";
import { useRouter } from "vue-router";


const props = withDefaults(
  defineProps<{
    list: MenuType[]
    isShowMenu: boolean
    isShowTitle: boolean
  }>(),
  {
    list: () => new Array<MenuType>(),
    isShowMenu: true,
    isShowTitle: true
  }
);

const data = reactive({
  activeIndex: "",
  menuList: new Array<MenuType>()
});

const handleSelect = (key: string, keyPath: string[]) => {
  console.log(key, keyPath);
};
const emit = defineEmits(["exportsOpenStatus"]);
const clickHandle = () => {
  emit("exportsOpenStatus");
};

const loginHandle = () => {
  window.location.href = "https://rcar.vip/login";
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 46px;
  color: #fff;
  background: linear-gradient(90deg, #4f8dee 0%, #5684ec 100%);
  position: fixed;
  z-index: 99999;

  .content {
    height: 100%;
    line-height: 46px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .logo {
      height: 100%;
      display: flex;
      flex-direction: row;

      .img {
        img {
          width: auto;
          height: 100%;
        }
      }

      .name {
        font-size: 22px;
        margin: 0 5px;
      }
    }

    .v-bar {
      margin: 0 15px;
    }

    .menu {
      flex: 1;
      display: flex;

      .body {
        height: 100%;
        width: 100%;
        justify-content: center;
      }

      :deep(.el-menu) {
        height: 100%;
        --el-menu-bg-color: transparent;
        --el-menu-text-color: #fff;
        --el-menu-active-color: #fff;
        --el-menu-hover-bg-color: transparent;
        --el-menu-hover-text-color: #fff;
        --el-menu-item-font-size: 16px;
      }

      :deep(.el-sub-menu__title:hover) {
        background-color: transparent;
      }

      :deep(.el-menu--horizontal) {
        border-bottom: none;
      }
    }

    .other {
      display: flex;

      .login {
        cursor: pointer;

        &:hover {
          color: #c6e2ff;
        }
      }

      .tryOut {
      }
    }

    .menu-btn {
      width: 46px;
      height: 100%;
      font-size: 30px;

      .el-icon {
        width: 46px;
        height: 46px;
        line-height: 46px;
      }
    }
  }
}
</style>
