import { createRouter, createWebHistory } from 'vue-router'
import { routes } from './router.config'

const router = createRouter({
  history: createWebHistory(),
  routes
})

// router.beforeEach((to, from, next) => {
//   //在跳转路由之前，先清除所有的请求
//   clearPending()
//   // ...
//   const user = localStorage.getItem('user')
//   const token = user ? JSON.parse(user).usertoken : ''
//   if (!token) {
//     if (['/', '/login'].includes(to.path)) {
//       next()
//     } else {
//       next('/')
//     }
//   } else {
//     if (['/', '/login'].includes(to.path)) {
//       next('/home')
//     } else {
//       next()
//     }
//   }
// })

export default router
