<template>
  <div class="layout">
    <!--顶部导航栏-->
    <Header
      :is-show-menu="data.isShowMenu"
      :is-show-title="data.isShowTitle"
      :list="data.menu"
      @exportsOpenStatus="changeOpenStatus" />
    <!--路由内容-->
    <div class="main">
      <FadeTransition
        :duration="300"
        :in-transition-name="'animate__fadeInDown'"
        :out-transition-name="'animate__fadeOutUp'">
        <div v-if="data.openStatus" class="dask">
          <div class="list">
            <el-menu :default-active="data.activeIndex" :ellipsis="false" class="el-menu-demo">
              <template v-for="item in data.menu" :key="item.id">
                <el-menu-item v-if="!item.children" :index="item.id.toString()">
                  <template #title>
                    <div :title="item.title">{{ item.title }}</div>
                  </template>
                </el-menu-item>
                <el-sub-menu v-else :index="item.id.toString()">
                  <template #title>
                    <div :title="item.title">{{ item.title }}</div>
                  </template>
                  <el-menu-item
                    v-for="val in item.children"
                    :key="val.id"
                    :index="item.id + '-' + val.id">
                    <template #title>
                      <div :title="item.title">{{ item.title }}</div>
                    </template>
                  </el-menu-item>
                </el-sub-menu>
              </template>
            </el-menu>
          </div>
        </div>
      </FadeTransition>
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Header from "@/components/comments/Header.vue";
import { reactive, watch } from "vue";
import { MenuType } from "@/views/typs";
import FadeTransition from "@/components/comments/Transition/FadeTransition.vue";

const data = reactive({
  menu: new Array<MenuType>(),
  menuList: new Array<MenuType>(),
  isPhone: false,
  isShowMenu: true,
  isShowTitle: true,
  openStatus: false,
  activeIndex: ""
});

watch(
  () => data.isShowMenu,
  (newVal) => {
    if (newVal) {
      data.openStatus = false;
    }
  }
);

const computedMenuList = (list: MenuType[]) => {
  list.forEach((item) => {
    if (item.children) {
      computedMenuList(item.children);
    } else {
      item.id = data.menuList.length;
      data.menuList.push(item);
    }
  });
};

const checkShowStatus = () => {
  const width = document.body.clientWidth;
  if (width <= 600) {
    data.isShowMenu = false;
    data.isShowTitle = false;
  } else if (width > 600 && width < 960) {
    data.isShowMenu = false;
    data.isShowTitle = true;
  } else if (width >= 960 && width < 1180) {
    data.isShowMenu = true;
    data.isShowTitle = false;
  } else {
    data.isShowMenu = true;
    data.isShowTitle = true;
  }
};
const initMenuList = () => {
  data.menu = data.menu.concat([
    {
      id: 0,
      name: "home",
      title: "首页",
      url: ""
    },
    {
      id: 1,
      name: "production",
      title: "公司产品",
      children: [
        {
          id: 0,
          name: "hjc",
          title: "慧驾车",
          url: ""
        },
        {
          id: 1,
          name: "hiClientsSCRM",
          title: "HiClients SCRM",
          url: ""
        },
        {
          id: 2,
          name: "carOwnerPortrait",
          title: "车主画像",
          url: ""
        },
        {
          id: 3,
          name: "hgc",
          title: "慧管车",
          url: ""
        }
      ]
    },
    {
      id: 2,
      name: "scenes",
      title: "使用场景",
      children: [
        {
          id: 0,
          name: "comCar",
          title: "综修厂",
          url: ""
        },
        {
          id: 1,
          name: "repairCar",
          title: "汽修厂",
          url: ""
        },
        {
          id: 2,
          name: "4sShop",
          title: "4S店",
          url: ""
        }
      ]
    },
    {
      id: 3,
      name: "activate",
      title: "立即开通",
      url: ""
    }
  ]);

  computedMenuList(data.menu);
  data.menuList.push({
    id: data.menuList.length,
    name: "login",
    title: "登录",
    url: ""
  });
  data.menuList.push({
    id: data.menuList.length,
    name: "tryOut",
    title: "申请试用",
    url: ""
  });

  checkShowStatus();
};

initMenuList();

document.body.onresize = () => {
  checkShowStatus();
};

const changeOpenStatus = () => {
  data.openStatus = !data.openStatus;
};
</script>

<style lang="scss" scoped>
.layout {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .main {
    flex: 1;
    position: relative;
    padding-top: 46px;
    width: 100%;
    height: 100%;

    .dask {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 46px;
      left: 0;
      background: rgba(237, 239, 243, 0.6);
      z-index: 999;

      .list {
        padding: 20px;

        :deep(.el-menu) {
          background-color: transparent;
          border-right: none;
        }

        :deep(.el-sub-menu__title) {
          border-radius: 10px;
        }

        :deep(.el-menu-item) {
          border-radius: 10px;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
